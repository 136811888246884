import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import { useTranslation } from "react-i18next";
import NumberSteps from "components/ui/base/NumberSteps";
import Customers from "components/ui/extended/Customers";
import ConversationInboxTabs from "components/ui/extended/ConversationInboxTabs";
import Savings from "components/ui/extended/HelpCenterSavings";
import TestimonialWithImageFrancesca from "components/ui/extended/TestimonialWithImageFrancesca";
import RegisterForSaaS from "components/ui/extended/RegisterForSaaS";
import MobileBox from "components/ui/extended/MobileBox";
import HorizontalStoriesMenu from "components/ui/base/HorizontalStoriesMenu";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import MarketingAutomationAltFeatures from "components/ui/extended/MarketingAutomationAltFeatures";
import { Integrations } from "components/ui/extended/Integrations";
import SaasSuccessStories from "components/ui/extended/SaasSuccessStories";

interface SaaSPageProps {
  location: Location;
}

export default ({ location }: SaaSPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.industries.software-as-a-service.seo.title")}
        description={t(
          "pages.industries.software-as-a-service.seo.description"
        )}
        image={{
          relativePath: "meta/live-chat-for-saas-software-it.jpg",
          alt: "Live Chat for SaaS Software ",
        }}
      />

      <PlainHeaderWithVideo
        title={t("pages.industries.software-as-a-service.title")}
        description={t("pages.industries.software-as-a-service.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={900}
        descriptionMaxWidth={700}
        wistia={{
          id: "d74r25ulyu",
        }}
      />

      <Customers />

      <HorizontalStoriesMenu
        title={t("pages.industries.software-as-a-service.assist.title")}
        paragraph={t("pages.industries.software-as-a-service.assist.paragraph")}
        storiesPosition={"left"}
        elements={[
          {
            title: t(
              "pages.industries.software-as-a-service.assist.liveChat.title"
            ),
            description: t(
              "pages.industries.software-as-a-service.assist.liveChat.description"
            ),
            story: {
              relativePath: "pages/live-chat/live-chat-superpowers.mov",
            },
          },
          {
            title: t(
              "pages.industries.software-as-a-service.assist.videoLiveChat.title"
            ),
            description: t(
              "pages.industries.software-as-a-service.assist.videoLiveChat.description"
            ),
            story: {
              relativePath: "pages/video-live-chat/video-live-chat.mov",
            },
          },
          {
            title: t(
              "pages.industries.software-as-a-service.assist.smartAssignmentRules.title"
            ),
            description: t(
              "pages.industries.software-as-a-service.assist.smartAssignmentRules.description"
            ),
            story: {
              relativePath: "pages/live-chat/smart-assignment-rules.mov",
            },
          },
        ]}
      />

      <NumberSteps
        title={t("pages.industries.software-as-a-service.steps.title")}
        elements={Array.from(Array(3).keys()).map((i) => {
          return {
            title: t(
              `pages.industries.software-as-a-service.steps.${i + 1}.title`
            ),
            description: t(
              `pages.industries.software-as-a-service.steps.${
                i + 1
              }.description`
            ),
          };
        })}
      />

      <ConversationInboxTabs />

      <Savings />

      <ImageWithDescription
        title={t("pages.industries.software-as-a-service.helpCenter.title")}
        description={t(
          "pages.industries.software-as-a-service.helpCenter.subtitle"
        )}
        cta={{
          text: t("pages.industries.software-as-a-service.helpCenter.cta"),
          link: "/help-center/",
        }}
        image={{
          relativePath:
            "pages/industries/software-as-a-service/help-center-software-for-saas.jpg",
          alt: t("pages.industries.software-as-a-service.helpCenter.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.industries.software-as-a-service.autoreply.title")}
        description={t(
          "pages.industries.software-as-a-service.autoreply.subtitle"
        )}
        cta={{
          text: t("pages.industries.software-as-a-service.autoreply.cta"),
          link: "/help-center/",
        }}
        image={{
          relativePath:
            "pages/industries/software-as-a-service/help-center-software-for-saas-autoreply-bot.jpg",
          alt: t("pages.industries.software-as-a-service.autoreply.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <TestimonialWithImageFrancesca />

      <MobileBox />

      <HorizontalStoriesMenu
        title={t("pages.industries.software-as-a-service.automate.title")}
        paragraph={t(
          "pages.industries.software-as-a-service.automate.paragraph"
        )}
        storiesPosition={"left"}
        cta={{
          text: t("pages.industries.software-as-a-service.automate.cta"),
          link: "/marketing-funnel/",
        }}
        elements={[
          {
            title: t(
              "pages.industries.software-as-a-service.automate.incomingLead.title"
            ),
            story: {
              relativePath: "pages/live-chat-engagement/pre-chat-survey.mov",
            },
          },
          {
            title: t(
              "pages.industries.software-as-a-service.automate.campaign.title"
            ),
            story: {
              relativePath: "pages/crm/contact-list-builder.mp4",
            },
          },
          {
            title: t(
              "pages.industries.software-as-a-service.automate.behaviour.title"
            ),
            story: {
              relativePath:
                "pages/marketing-funnel/marketing-automation-funnel-template-builder.mp4",
            },
          },
        ]}
      />

      <ImageWithDescription
        title={t("pages.industries.software-as-a-service.upsell.title")}
        description={t(
          "pages.industries.software-as-a-service.upsell.subtitle"
        )}
        cta={{
          text: t("pages.industries.software-as-a-service.upsell.cta"),
          link: "/marketing-funnel/",
        }}
        image={{
          relativePath:
            "pages/industries/software-as-a-service/marketing-automation-funnel-template-to-upsell.jpg",
          alt: t("pages.industries.software-as-a-service.upsell.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <ImageWithDescription
        title={t("pages.industries.software-as-a-service.onboard.title")}
        description={t(
          "pages.industries.software-as-a-service.onboard.subtitle"
        )}
        cta={{
          text: t("pages.industries.software-as-a-service.onboard.cta"),
          link: "/marketing-funnel/",
        }}
        image={{
          relativePath:
            "pages/industries/software-as-a-service/marketing-automation-funnel-template-onboarding-for-saas.jpg",
          alt: t("pages.industries.software-as-a-service.onboard.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <MarketingAutomationAltFeatures location={location} />

      <ImageWithDescription
        title={t("pages.industries.software-as-a-service.segmentation.title")}
        description={t(
          "pages.industries.software-as-a-service.segmentation.subtitle"
        )}
        cta={{
          text: t("pages.industries.software-as-a-service.segmentation.cta"),
          link: "/audience-segmentation/",
        }}
        image={{
          relativePath:
            "pages/industries/software-as-a-service/business-intelligence-crm-contact-segmentation.jpg",
          alt: t("pages.industries.software-as-a-service.segmentation.title"),
          position: "left",
          fullWidth: true,
        }}
      />

      <Integrations
        title={t("pages.industries.software-as-a-service.integrations.title")}
        description={t(
          "pages.industries.software-as-a-service.integrations.description"
        )}
      />

      <HorizontalStoriesMenu
        title={t("pages.industries.software-as-a-service.satisfaction.title")}
        paragraph={t(
          "pages.industries.software-as-a-service.satisfaction.paragraph"
        )}
        storiesPosition={"left"}
        cta={{
          text: t("pages.industries.software-as-a-service.satisfaction.cta"),
          link: "/customer-satisfaction-tool/",
        }}
        elements={[
          {
            title: t(
              "pages.industries.software-as-a-service.satisfaction.survey.title"
            ),
            story: {
              relativePath: "pages/surveys/in-chat-survey.mov",
            },
          },
          {
            title: t(
              "pages.industries.software-as-a-service.satisfaction.nps.title"
            ),
            story: {
              relativePath: "pages/nps/net-promoter-score.mov",
            },
          },
          {
            title: t(
              "pages.industries.software-as-a-service.satisfaction.learn.title"
            ),
            story: {
              relativePath: "pages/nps/nps-feedback.mov",
            },
          },
        ]}
      />

      <RegisterForSaaS />

      <SaasSuccessStories />
    </GlobalWrapper>
  );
};
